import { useState, useContext } from 'react';
import { PostMessageContext } from 'component/postMessage/PostMessageContext';
import {
  Box,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableContainer,
  TableBody,
  TextField,
  Button,
  Typography,
  Card,
  CardContent,
  CardHeader,
  Link,
  InputAdornment,
} from '@mui/material';
import PaginationControls from 'component/base/PaginationControls';
import NoResultsFound from 'component/base/NoResultsTableRow';
import { SearchRounded, Edit } from '@mui/icons-material';
import { Skeleton } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useSites, useSitesUsers } from 'api/site';
import { useProfile } from 'api/profile';
import { formatDataSize } from 'utils/number';
import { useTranslation } from 'react-i18next';
import { ErrorBoundary } from 'react-error-boundary';
import Flag from 'react-flagpack';
import { isInIframe } from 'utils/iframe';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWordpress } from '@fortawesome/free-brands-svg-icons';
import { faComments, faLongArrowRight } from '@fortawesome/pro-regular-svg-icons';
import { ChangeLabelTooltip } from 'component/partial/ChangeLabelTooltip';
import { PendingSitesList } from './PendingSitesList';
import RocketLaptop from 'component/svg/rocket-laptop.svg?react';
import { SiteLockedPopover } from '../SiteLockedPopover';
import { getSiteLockedStatus } from 'utils/site';
import { RoleGuard } from 'component/base/RoleGuard';
import { formatDomainName } from 'utils/formatting';
import { usePaginatedListState } from 'component/hooks/usePaginatedListState';
import { useDebouncedInputProps } from 'component/hooks/useDebouncedInput';
import { usePaginatedListLoadingState } from 'component/hooks/usePaginatedListLoadingState';
import { TableHeadCell } from 'component/base/TableHeadCell';

export default function RowSkeleton({ showAtarimCell }: { readonly showAtarimCell: boolean }) {
  const rows = () => {
    return Array.from({ length: 3 }).map((v, i) => (
      <TableRow key={i}>
        <TableCell>
          <Skeleton />
        </TableCell>
        <TableCell>
          <Skeleton />
        </TableCell>
        <TableCell>
          <Skeleton />
        </TableCell>
        {showAtarimCell ? (
          <TableCell>
            <Skeleton />
          </TableCell>
        ) : null}
        <TableCell>
          <Skeleton />
        </TableCell>
        <TableCell>
          <Skeleton />
        </TableCell>
        <RoleGuard roles={['billing_admin']} type="block">
          <TableCell>
            <Skeleton />
          </TableCell>
        </RoleGuard>
      </TableRow>
    ));
  };

  return <>{rows()}</>;
}
export function SiteList({ isWpSiteControl }: { readonly isWpSiteControl: boolean }) {
  const { sendMessage, getSdk } = useContext(PostMessageContext);
  const navigate = useNavigate();

  const [siteToChangeLabel, setSiteToChangeLabel] = useState<number | null>(null);
  const tableState = usePaginatedListState();
  const { data: profileData } = useProfile();
  const sdk = getSdk();
  const isPlanNotStarter = Boolean(
    profileData?.data.result?.package?.plan && profileData?.data.result.package.plan !== 'Starter'
  );
  const showAtarim = sdk === null && isPlanNotStarter;

  const sitesNew = useSites(tableState, { enabled: !isWpSiteControl });
  const sitesUserNew = useSitesUsers(tableState, { enabled: isWpSiteControl });

  const { data, isLoading } = isWpSiteControl ? sitesUserNew : sitesNew;

  const { t } = useTranslation();

  const allSites = data?.data?.result || [];
  const sites = isWpSiteControl
    ? allSites.filter(site => site.status?.toLowerCase() === 'active')
    : allSites;

  const searchInputProps = useDebouncedInputProps(tableState.params.search, tableState.setSearch);
  const loadingState = usePaginatedListLoadingState(!!sites.length, isLoading, tableState.params);

  const renderTableRows = () => {
    if (isLoading) {
      return <RowSkeleton showAtarimCell={showAtarim} />;
    }

    return sites.map(site => {
      const location = site.location?.split(' - ') ?? '';
      const staticSite: boolean = site?.site_type === 1 || false;
      const lockedSite = getSiteLockedStatus(site);

      return (
        <TableRow key={site.id}>
          <TableCell>{formatDomainName(site.domain)}</TableCell>
          <TableCell>
            <Button variant="text" color="primary" onClick={() => setSiteToChangeLabel(site.id!)}>
              {site.label ? (
                <>
                  <Typography color="textPrimary">{site.label}</Typography>
                  <Box display="inline-block" marginLeft={1}>
                    <Edit />
                  </Box>
                </>
              ) : (
                <Typography color="textSecondary">{t('add_label')}</Typography>
              )}
            </Button>
          </TableCell>
          <TableCell>{formatDataSize(site.disk_usage)}</TableCell>
          <TableCell>
            <ErrorBoundary fallbackRender={() => location[0] ?? 'unknown'}>
              <Box data-testid="siteLocation">
                <Flag code={location[0] ?? 'US'} size="m" />{' '}
                {typeof location[1] === 'string' ? location[1] : site.location}
              </Box>
            </ErrorBoundary>
          </TableCell>
          {showAtarim && !staticSite ? (
            <TableCell>
              <Button
                onClick={() => {
                  if (isInIframe()) {
                    sendMessage(
                      JSON.stringify({
                        type: 'externalLink',
                        data: {
                          relative: false,
                          link: `https://app.atarim.io/fetching/?_from=rocket&url=https://${site.domain}`,
                        },
                      })
                    );
                  } else {
                    window.open(
                      `https://app.atarim.io/fetching/?_from=rocket&url=https://${site.domain}`
                    );
                  }
                }}
                variant="text"
                color="primary"
                startIcon={<FontAwesomeIcon icon={faComments} />}
                endIcon={<FontAwesomeIcon icon={faLongArrowRight} />}
              >
                {''}
              </Button>
            </TableCell>
          ) : null}
          {staticSite ? <TableCell>-</TableCell> : null}

          <RoleGuard roles={['billing_admin']} type="block">
            {!staticSite ? (
              <TableCell>
                <SiteLockedPopover isSiteLocked={lockedSite}>
                  <Button
                    onClick={() => {
                      if (isInIframe()) {
                        sendMessage(
                          JSON.stringify({
                            type: 'externalLink',
                            data: {
                              relative: true,
                              link: `/manage/${
                                site.id
                              }/wordpress?token=${window.sessionStorage.getItem('user_session')}`,
                            },
                          })
                        );
                      } else {
                        window.open(`/manage/${site.id}/wordpress`);
                      }
                    }}
                    variant="text"
                    color="primary"
                    startIcon={<FontAwesomeIcon icon={faWordpress} />}
                    endIcon={<FontAwesomeIcon icon={faLongArrowRight} />}
                    disabled={lockedSite}
                  >
                    {''}
                  </Button>
                </SiteLockedPopover>
              </TableCell>
            ) : (
              <TableCell>-</TableCell>
            )}
          </RoleGuard>
          <TableCell>
            <Link onClick={() => navigate(`/sites/${site.id}`)}>{t('manage')}</Link>
          </TableCell>
        </TableRow>
      );
    });
  };

  if (loadingState === 'noData') {
    return (
      <Card>
        <CardContent>
          <Box padding={5} alignContent="center" alignItems="center" textAlign="center">
            <RocketLaptop />
            <Box padding={1}>
              <Typography>{t('no_sites_message')}</Typography>
            </Box>
            {!isWpSiteControl && (
              <Box padding={1}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    navigate('/sites/create');
                  }}
                >
                  {t('create_site')}
                </Button>
              </Box>
            )}
          </Box>
        </CardContent>
      </Card>
    );
  }

  return (
    <>
      {siteToChangeLabel ? (
        <ChangeLabelTooltip
          key={siteToChangeLabel ?? 'none'}
          site={sites.find(site => site.id === siteToChangeLabel)!}
          onClose={() => setSiteToChangeLabel(null)}
        />
      ) : null}
      <Card>
        <CardHeader
          title={t('sites')}
          action={
            !isInIframe() &&
            !isWpSiteControl && (
              <RoleGuard roles={['billing_admin']} type="block">
                <Button
                  onClick={() => {
                    navigate('/sites/create');
                  }}
                  variant="contained"
                  color="primary"
                >
                  {t('create_site')}
                </Button>
              </RoleGuard>
            )
          }
        />
        <CardContent>
          <TableContainer>
            <TextField
              {...searchInputProps}
              variant="outlined"
              placeholder={t('search_sites_label')}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end" disableTypography component="button">
                    <SearchRounded />
                  </InputAdornment>
                ),
              }}
            />
            <PendingSitesList
              sites={allSites}
              isWpSiteControl={isWpSiteControl}
              tableState={tableState}
              filteredSitesList={sites}
            />
            <Table aria-label="Site List Table">
              <TableHead>
                <TableRow>
                  <TableHeadCell tableState={tableState} column="domain" label={t('domain')} />
                  <TableHeadCell tableState={tableState} column="label" label={t('label')} />
                  <TableHeadCell
                    tableState={tableState}
                    column="disk_usage"
                    label={t('disk_usage')}
                  />
                  <TableCell>{t('location')}</TableCell>
                  {showAtarim ? <TableCell>{t('atarim')}</TableCell> : null}
                  <RoleGuard roles={['billing_admin']} type="block">
                    <TableCell>{t('wp_admin')}</TableCell>
                  </RoleGuard>
                  <TableCell width={110}>{t('action')}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {loadingState === 'noResults' ? (
                  <NoResultsFound colSpan={showAtarim ? 7 : 6} />
                ) : (
                  renderTableRows()
                )}
              </TableBody>
            </Table>
          </TableContainer>

          <PaginationControls
            page={tableState.params.page}
            totalRowCount={data?.data.metadata?.total || 0}
            perPage={tableState.params.perPage}
            perPageOptions={tableState.config.perPageOptions}
            onPageChange={tableState.setPage}
            onPerPageChange={tableState.setPerPage}
          />
        </CardContent>
      </Card>
    </>
  );
}
