import { useAcceptInviteLoggedIn } from 'api/auth';
import { Box, Table, TableHead, TableRow, TableCell, TableBody, Link, Button } from '@mui/material';
import { useTranslation } from 'react-i18next';
import NoResultsFound from 'component/base/NoResultsTableRow';
import { useNavigate } from 'react-router-dom';
import { components } from 'openapi-types';
import { PaginatedListState } from 'component/hooks/usePaginatedListState';
import { TableHeadCell } from 'component/base/TableHeadCell';

type Sites = Pick<components['schemas']['Site'], 'status' | 'id' | 'domain' | 'label'>[];

type Props = {
  readonly isWpSiteControl: boolean;
  readonly sites: Sites;
  readonly tableState: PaginatedListState;
  readonly filteredSitesList: Sites;
};

export const PendingSitesList = ({
  isWpSiteControl,
  sites,
  tableState,
  filteredSitesList,
}: Props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { mutateAsync } = useAcceptInviteLoggedIn();

  if (!isWpSiteControl || !sites.some(site => site.status?.toLowerCase() === 'invited')) {
    return null;
  }

  return (
    <Table aria-label="Pending Sites Table" sx={{ marginBottom: 4 }}>
      <TableHead>
        <TableRow>
          <TableHeadCell tableState={tableState} column="domain" label={t('domain')} />
          <TableHeadCell tableState={tableState} column="label" label={t('domain')} />
          <TableCell>{t('invite_status')}</TableCell>
          <TableCell width={150}>{t('action')}</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {tableState.params.search !== '' && filteredSitesList.length === 0 ? (
          <NoResultsFound colSpan={4} />
        ) : (
          sites
            .filter(site => site.status?.toLowerCase() === 'invited')
            .map(site => (
              <TableRow key={site.id}>
                <TableCell>{site.domain}</TableCell>
                <TableCell>{site.label}</TableCell>
                <TableCell>{site.status}</TableCell>
                <TableCell>
                  <Button
                    variant="text"
                    onClick={async () => {
                      await mutateAsync({ siteId: site.id! });
                      navigate(`/sites/${site.id}`);
                    }}
                  >
                    {t('accept_invite')}
                  </Button>
                </TableCell>
              </TableRow>
            ))
        )}
      </TableBody>
    </Table>
  );
};
